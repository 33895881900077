import {questionsMeta, structure} from "../structure";
import stateManager from "../stateManager";
import utils from "../utils";

export const task_2_1 = function(currentState, stt){
    const $page = $('.page-question');
    const $title = $page.find('.general .title');
    const $task = $page.find('.task .ph-wrapper');
    const $buttonSkip = $page.find('.button.next');

    const meta = questionsMeta[currentState];
    const variant = structure.variant[1][0];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant].title[1]);

    let currentOption = 0;
    let answer = [];

    let answerStartTime = 0;
    let allowToFinish = true;

    const processSpeech = function(words, text, numbers, isFinal){

        // console.log(numbers);

        numbers = _.difference(numbers, answer);

        _.each(numbers, function (number, index){
            answer.push(number);
            $task.eq(currentOption).find('.placeholder').html(number);
            $task.eq(currentOption).addClass('done');
            currentOption++;
            if(currentOption < 5) {
                $task.removeClass('active').eq(currentOption).addClass('active');
                const subtitle = meta.variants[variant].title[currentOption + 1] || meta.variants[variant].title[2];
                $title.fadeOut(200, () => $title.html(utils.formatTitle(subtitle)).fadeIn(200));
            }
        });

        if(currentOption === 5){
            setTimeout(() => finish(answer), 600);
        }
    };

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            stt.finishRecording();
            $page.find('.question').addClass('done');
            $page.find('.record').removeClass('active');
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = async () => {
        utils.sendYMGoal('question2Started');
        await stt.startRecording(processSpeech);
        answerStartTime = Date.now();
        $title.fadeOut(200, () => $title.html(title).fadeIn(200));
        $page.find('.morph').addClass('active');
        $page.find('.record').addClass('active');
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    void startTask();
};