import {questionsMeta, structure} from "../structure";
import utils from "../utils";
import stateManager from "../stateManager";

export const task_3_1 = function(currentState){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');

    let meta = questionsMeta[currentState];
    const variant = structure.variant[3];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant[0]].title[1]);

    const subjects = [];
    const options = [];
    _.each(variant, function(index){
        subjects.push(meta.variants[index].subject);
        options.push(meta.variants[index].options);
    });

    let currentSlide = 0;
    let answer = [];
    let answerStartTime = 0;
    let allowToFinish = true;

    const optionsCount = 5;
    const maxSelectedCount = 2;

    const processAnswer = function (){
        let $options = $page.find('#answer .subtask').removeClass('active').eq(currentSlide).addClass('active').hide().fadeIn(400);
        $options.find('.subject').html(subjects[currentSlide]);
        $options.find('.buttons').empty();
        $page.find('.pagination span').eq(currentSlide).addClass('active');
        let optionsSelected = 0;

        for(let i = 1; i <= optionsCount; i++){
            $('<div>', {
                class: 'button option',
                text: options[currentSlide][i-1]
            }).appendTo($options.find('.buttons')).on('click', function(){
                if(!$(this).hasClass('active')) {

                    if (optionsSelected < maxSelectedCount) {
                        $(this).addClass('active');
                        answer.push($(this).text());
                        optionsSelected++;

                        if (optionsSelected === maxSelectedCount) {

                            if (currentSlide !== (meta.subcount - 1)) {
                                currentSlide++;
                                $options.fadeOut(400, () => processAnswer());
                            } else {
                                //console.log('2 buttons pressed, can proceed');
                                // console.log(_.chunk(answer, 2));
                                finish(answer);
                            }
                        }
                    }

                    //console.log(answer);
                }

            });
        }
    }

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = () => {
        utils.sendYMGoal('question4Started');
        answerStartTime = Date.now();
        processAnswer();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(title).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();

};